<template>
  <!-- <div>大模块效果测评完成页   没使用到</div> -->
  <EndTestBackground :testType="2">
    <b-container class="effecr-evaluation-end">
      <b-row  class="text-spacing">
        <b-col>
          <span class="green">元昊同学</span>，恭喜你完成<span class="green"
            >中考英语冲刺沙漠行动效果测评</span
          >
        </b-col>
      </b-row>
      <b-row  class="text-spacing">
        <b-col> 本次测评得分：<span class="green">115分</span> </b-col>
      </b-row>
      <b-row  class="text-spacing">
        <b-col>
          获得 测评答题
          <span class="green"><pybWord :pybNum="50"></pybWord></span> 关卡奖励
          <span class="green"><pybWord :pybNum="25"></pybWord></span
          >，已纳入你的后勤补给！
        </b-col>
      </b-row>
      <b-row  class="text-spacing">
        <b-col>
          <span class="green">抢滩登陆</span>关卡已自动解锁，欢迎挑战！
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right"
          ><GlobalButton
            buttonType="round"
            buttonText="回大本营"
            class="big-end-form-btn"
            @afterClick="returnHome"
          ></GlobalButton
        ></b-col>
        <b-col class="text-left big-end-form-btn-right">
          <span @click="viewReport">查看报告</span>
        </b-col>
      </b-row>
    </b-container>
  </EndTestBackground>
</template>

<script>
import EndTestBackground from "@/components/backgroung/end-test-background.vue";
import pybWord from "@/components/key-words/pyb-key-word.vue";
import GlobalButton from "@/components/global-button/global-button-enter.vue";
export default {
  components: {
    EndTestBackground,
    pybWord,
    GlobalButton,
  },
  methods: {
    returnHome() {
      this.$router.replace({
        name: "HomeIndex",
      });
    },
    viewReport() {
      this.$router.replace({
        name: "BigModuleEffectReport",
        query: {
          // module: 6, //报告页面背景
          examId: this.$route.query.examId,
          sprintId: this.$route.query.sprintId,
          text:2
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.effecr-evaluation-end {
  min-height: 200px;
}
.green {
  color: $text-green;
}
.text-spacing {
  margin-bottom: 2%;
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}
.big-end-form-btn-right {
  margin-top: 10px;
  span {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>